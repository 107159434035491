import { Link } from "gatsby";

import React from "react";

import brand from "../img/brand.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false
    };
  }

  toggleHamburger = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  };

  // componentDidMount() {
  //   window.addEventListener("scroll", _.debounce(this.handleScroll, 500));
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  // handleScroll = () => {
  //   const { prevScrollpos } = this.state;

  //   const currentScrollPos = window.pageYOffset;
  //   const visible = prevScrollpos > currentScrollPos;

  //   this.setState({
  //     prevScrollpos: currentScrollPos,
  //     visible
  //   });
  // };

  render() {
    return (
      <>
        <header className="flex bg-white p-4 md:p-8 sticky w-full top-0 z-30">
          <div className="flex items-center justify-between container mx-auto">
            <div className="w-12" />
            <Link
              className="flex flex-col items-center flex-shrink-0 text-black-500 hover:opacity-75"
              to="/"
            >
              <img
                src={brand}
                alt="Louise Scott Textile Designer"
                className="h-3 md:h-4 block mb-1 md:mb-3"
              />

              <span
                className="font-bold text-base uppercase opacity-50"
                style={{ letterSpacing: "0.6rem", fontSize: "0.6rem" }}
              >
                Textile Design
              </span>
            </Link>
            <div className="block ml-2">
              <button
                className={`nav-btn ${this.state.showMobileMenu ? "hide" : ""}`}
                onClick={() => this.toggleHamburger()}
              >
                <svg
                  className="fill-current h-4 md:h-6 w-4 md:w-6"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
          </div>
        </header>
        <nav className={`nav ${this.state.showMobileMenu ? "show" : ""}`}>
          <button
            className="absolute top-0 right-0 mx-10 my-10 text-gray-800 hover:text-gray-600"
            onClick={() => this.toggleHamburger()}
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Close menu</title>
              <g id="Layer1">
                <g transform="matrix(0.707107,0.707107,-0.707107,0.707107,10,-4.14214)">
                  <rect x="0" y="9" width="20" height="2" />
                </g>
                <g transform="matrix(0.707107,-0.707107,0.707107,0.707107,-4.14214,10)">
                  <rect x="0" y="9" width="20" height="2" />
                </g>
              </g>
            </svg>
          </button>
          <div className="flex flex-col flex-1 p-10 font-normal">
            <Link
              to="/"
              className="my-2"
              activeClassName="text-gray-900 font-semibold"
            >
              Home{" "}
            </Link>
            <Link
              to="about"
              className="my-2"
              activeClassName="text-gray-900 font-semibold"
            >
              About{" "}
            </Link>
            <Link
              to="services"
              className="my-2"
              activeClassName="text-gray-900 font-semibold"
            >
              Services{" "}
            </Link>
            <a className="my-2" href="mailto:info@louisescotttextiles.com" target="_blank">
              Contact
            </a>
          </div>
        </nav>
      </>
    );
  }
};

export default Navbar;
