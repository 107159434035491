import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="bg-white shadow p-6 hidden">
        <div className="container mx-auto flex">
          <div className="w-full mx-auto flex flex-wrap">
            <div className="block w-full">
              <p className="text-center text-gray-600 text-sm w-full">
                &copy; 2019. Louise Scott Textile Design.
                {/* <Link to="about" className="mr-5" activeClassName="text-gray-900">About </Link>
                <Link to="services" className="mr-5" activeClassName="text-gray-900">Services </Link>
                <a href="mailto:info@louisescotttextiles.com" target="_blank">Contact</a> */}
              </p>
            </div>
            {/*<div className="flex w-full md:w-1/4">
              <div className="pr-6 pb-4">
                <h3 className="font-bold text-gray-900">Useful</h3>
                <ul className="list-none p-0 m-0 text-sm">
                  <li>
                    <Link
                      className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1"
                      to="/"
                    >
                      Returns Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1"
                      to="/"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1"
                      to="/help"
                    >
                      Help
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex w-full md:w-1/4">
              <div className="pb-4">
                <h3 className="font-bold text-gray-900 mb-2">Social</h3>
                <a
                  title="facebook"
                  className="inline-block mr-4 hover:text-gray-700"
                  href="https://facebook.com"
                >
                  <IoLogoFacebook className="fill-current w-6 h-6" />
                </a>
                <a
                  title="twitter"
                  className="inline-block mr-4 hover:text-gray-700"
                  href="https://twitter.com"
                >
                  <IoLogoTwitter className="fill-current w-6 h-6" />
                </a>
                <a
                  title="instagram"
                  className="inline-block mr-4 hover:text-gray-700"
                  href="https://instagram.com"
                >
                  <IoLogoInstagram className="fill-current w-6 h-6" />
                </a>
                <a
                  title="vimeo"
                  className="inline-block mr-4 hover:text-gray-700"
                  href="https://vimeo.com"
                >
                  <IoLogoVimeo className="fill-current w-6 h-6" />
                </a>
              </div>
            </div>*/}
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
