import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Grid from "../components/Grid";

const IndexPage = ({ data }) => {
  const { fields, frontmatter } = data.allMarkdownRemark.edges[0].node;
  return (
    <Layout>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <section className="flex-1 p-3 md:py-8 lg:py-16">
        <div className="container mx-auto">
          <Grid gridItems={frontmatter.images} />
        </div>
      </section>
      
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "Home" } } }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM / YY")
            title
            short_description
            description
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 740, quality: 74) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              caption
            }
            tags
          }
        }
      }
    }
  }
`;
