import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import Reveal from "react-reveal/Reveal";

import Masonry from "react-masonry-css";

const FeatureGrid = ({ gridItems }) => (
  <Masonry
    breakpointCols={{
      default: 2,
      1100: 2,
      700: 2,
      500: 1
    }}
    className="grid"
    columnClassName="grid-column"
  >
    {gridItems.length > 0 &&
      gridItems.map(item => (
        <div key={item.text} className="grid-item">
          <Reveal effect="fadeInUp">
            <div>
              <div>
                <PreviewCompatibleImage imageInfo={item} />
              </div>
              <div className="caption">
                <p className="text-xl tracking-wider p-5 text-center">
                  {item.caption}
                </p>
              </div>
            </div>
          </Reveal>
        </div>
      ))}
    {/* <Masonry
  breakpointCols={{
  default: 4,
  1100: 3,
  700: 2,
  500: 1
}}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
  <div>My Element</div>
  <div>My Element</div>
  <div>My Element</div>
  <div>My Element</div>
</Masonry> */}
  </Masonry>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      caption: PropTypes.string
    })
  )
};

export default FeatureGrid;
