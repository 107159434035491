import React from "react";
import Helmet from "react-helmet";

import bg from "../img/bg.jpg";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import PageTransition from "./PageTransition";

import "../styles/app.css";

//import '../styles/all.sass'
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = props => {
  const { children, context, location } = props;
  const { title, description } = useSiteMetadata();

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal flex flex-col min-h-screen">
      <Helmet titleTemplate={`${title} - %s`}>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="stylesheet" href="https://use.typekit.net/ixr6mxj.css" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />

        <meta
          name="google-site-verification"
          content="KbW9E1jk5tfXI8RSUgKJl2eG9ur5bqha42E4FMRLhrg"
        />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Navbar />
      <div className="main flex flex-1 flex-col relative">
        <PageTransition location={location}>{children}</PageTransition>
      </div>
      {/* <div className="main flex flex-col flex-1">{children}</div> */}
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
