import React from "react";
import { useTransition, animated } from "react-spring";

const PageTransition = props => {
  const { children, location } = props;
  if (!(location && location.pathname)) {
    return <div className="main flex-1">{children}</div>;
  }
  const transitions = useTransition(location.pathname, null, {
    //config: config.slow,
    native: true,
    from: {
      opacity: 0,
      transform: "translate3d(0,-20px,0)",
      position: "relative"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0,0)",
      position: "relative"
    },
    leave: {
      opacity: 0,
      pointerEvents: "none",
      transform: "translate3d(0,10px,0)",
      position: "absolute"
    },
    delay: 5
  });
  return transitions.map(({ item, key, props }) => (
    <animated.div
      key={key}
      className="w-full top-0"
      style={{
        opacity: props.opacity,
        position: props.position,
        //transform: props.transform
      }}
    >
      {children}
    </animated.div>
  ));
};

export default PageTransition;
